import * as React from 'react';
import { Element } from 'react-scroll';

import Spotlight from '../About/Spotlight';

import CTA from '../../components/shared/CTA';
import Team from '../Team';

import Footer from '../../components/shared/Footer';
import Header from '../../components/shared/Header';
import Partners from '../../components/shared/Partners';
import RWS from '../../components/shared/RWS';

import Services from '../../components/shared/Services';
import Head from '../../components/ui/Head';
import Page from '../../components/ui/Page';
import ContactInfo from '../ContactUs/ContactInfo';

import Hero from './Hero';
// import Innovation from './Innovation';

import './style.scss';

export interface LandingProps {
	pageTitle?: string;
}

class Landing extends React.Component {
	constructor(props: LandingProps, context?: any) {
		super(props, context);
		this.handleCtaClick = this.handleCtaClick.bind(this);
	}
	private helloRef = React.createRef<HTMLDivElement>();
	public clickRef = () => {
		if (this.helloRef && this.helloRef.current) {
			this.helloRef.current.scrollIntoView();
		}
	};
	public handleCtaClick() {
		window.open('https://wa.me/27833734744', '_blank');
	}

	public render() {
		return (
			<Page>
				<Head>
					<title>RSS</title>
					<meta name="Description" content="RSS" />
				</Head>
				<Element name="Home" className="element">
					<Header theme="dark">
						<div className="Landing__CTA">
							<div className="Landing__Logo" />
							<h3 className="Landing__Title u-title">RSS</h3>
						</div>
					</Header>
				</Element>
				{/* <Innovation /> */}
				<Element name="AboutUs" className="element">
					<Spotlight />
				</Element>
				<Element name="Services" className="element">
					<Services />
				</Element>
				<Hero onCtaClick={this.handleCtaClick} />
				<Element name="Partners" className="element">
					<Partners />
				</Element>
				<br />
				<RWS />
				<Team />
				<CTA onCtaClick={this.handleCtaClick} />
				<Element name="ContactUs" className="element">
					<ContactInfo />
				</Element>

				<Footer />
			</Page>
		);
	}
}

export default Landing;
