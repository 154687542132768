import * as React from 'react';

import GoogleMap from '../../../containers/GoogleMap';

import './style.scss';

export interface ContactInfoProps {
	google?: any;
}

const ContactInfo = (props: ContactInfoProps) => (
	<div className="ContactInfo__Section u-wrapper">
		<div className="ContactInfo-list">
			<blockquote className="ContactInfo">
				<div className="ContactInfo__inner">
					<header>
						<h4 className="ContactInfo__title">Contact Details</h4>
					</header>
					<div className="ContactInfo__list-item">
						<div className="ContactInfo__icon fa fa-phone" />
						<a className="ContactInfo__content" href="tel:+27833734744">
							+27 21 880 1119
						</a>
					</div>
					<div className="ContactInfo__list-item">
						<div className="ContactInfo__icon fa fa-tablet" />
						<a className="ContactInfo__content" href="tel:+27833734744">
							+27 83 373 4744
						</a>
					</div>
					<div className="ContactInfo__list-item">
						<div className="ContactInfo__icon fa fa-envelope" />
						<a className="ContactInfo__content" href="mailto:info@rss.world">
							info@rss.world
						</a>
					</div>
					<div className="ContactInfo__list-item">
						<div className="ContactInfo__icon fa fa-map-marker" />
						<a className="ContactInfo__content" target="_blank" href="https://goo.gl/3837x3">
							Unit D4, Carpe Diem, 26 Quantum Street, Techno Park, Stellenbosch (7600), Western
							Cape, ZA
						</a>
					</div>
				</div>
			</blockquote>

			<blockquote className="ContactInfo">
				<div className="ContactInfo__map">
					<GoogleMap google={props.google} />
				</div>
			</blockquote>
		</div>
	</div>
);

export default ContactInfo;
