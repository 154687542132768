import * as React from 'react';
import { Link } from 'react-scroll';

import Grid from '../../ui/Grid';
import Nav from '../../ui/Nav';
// import Link from '../../ui/RouterLink';

import './style.scss';

const Footer = () => (
	<footer className="Footer u-wrapper">
		<div className="Navigation">
			<Grid wrap={true}>
				<div className="Navigation__column">
					<h5 className="Navigation__head">Navigation</h5>
					<Nav stack={true}>
						<Link activeClass="active" to="Home" spy={true} smooth={true} offset={0} duration={500}>
							<span className="Navigation__link">Home</span>
						</Link>

						<Link
							activeClass="active"
							to="AboutUs"
							spy={true}
							smooth={true}
							offset={50}
							duration={500}
						>
							<span className="Navigation__link">About Us</span>
						</Link>
						<Link
							activeClass="active"
							to="ContactUs"
							spy={true}
							smooth={true}
							offset={50}
							duration={500}
						>
							<span className="Navigation__link">Contact Us</span>
						</Link>
					</Nav>
				</div>

				<div className="Navigation__column Navigation__column--split">
					<h5 className="Navigation__head">Connect</h5>
					<Nav stack={false}>
						<div className="ContactInfo__list-item">
							<a className="Navigation__link" target="_blank" href="https://twitter.com/binary_specific">
								<div className="fa fa-twitter fa-2" />
							</a>
						</div>

						<div className="ContactInfo__list-item">
							<a
								className="Navigation__link"
								target="_blank"
								href="https://www.facebook.com/RSoftwareSystems"
							>
								<div className="fa fa-facebook-square fa-2" />
							</a>
						</div>
						<div className="ContactInfo__list-item">
							<a className="Navigation__link" target="_blank" href="https://wa.me/27833734744">
								<div className="fa fa-whatsapp fa-2" />
							</a>
						</div>
						<div className="ContactInfo__list-item">
							<a
								className="Navigation__link"
								target="_blank"
								href="https://www.linkedin.com/company/rss-systems-za"
							>
								<div className="fa fa-linkedin-square fa-2" />
							</a>
						</div>
						<div className="ContactInfo__list-item">
							<a className="Navigation__link" target="_blank" href="mailto:info@rss.world">
								<div className="fa fa-envelope fa-2" />
							</a>
						</div>
					</Nav>
				</div>
			</Grid>
		</div>

		<div className="Copyrights">&copy; 2020 by RSS. All rights reserved.</div>
	</footer>
);

export default Footer;
