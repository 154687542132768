import * as React from 'react';
import Particles from 'react-particles-js';
import ReactRotatingText from 'react-rotating-text';

// import Button from '../../../components/ui/Button';

import './style.scss';

export interface HeroProps {
	onCtaClick?: () => void;
}

const Hero = (props: HeroProps) => (
	<div className="Hero__Section">
		<div>
			<Particles
				className="Hero__particles"
				params={{
					particles: {
						number: {
							value: 300,
							density: {
								enable: true,
								value_area: 1200
							}
						},
						color: {
							value: '#ffffff'
						},
						shape: {
							type: 'circle',
							stroke: {
								width: 0,
								color: '#000000'
							},
							polygon: {
								nb_sides: 5
							}
						},
						opacity: {
							value: 1,
							random: true,
							anim: {
								enable: true,
								speed: 1,
								opacity_min: 0,
								sync: false
							}
						},
						size: {
							value: 2,
							random: true,
							anim: {
								enable: false,
								speed: 6,
								size_min: 0.3,
								sync: false
							}
						},
						line_linked: {
							enable: true,
							distance: 150,
							color: '#ffffff',
							opacity: 0.4,
							width: 1
						},
						move: {
							enable: true,
							speed: 2,
							direction: 'none',
							random: true,
							straight: false,
							out_mode: 'out',
							bounce: false,
							attract: {
								enable: false,
								rotateX: 600,
								rotateY: 600
							}
						}
					},
					interactivity: {
						detect_on: 'canvas',
						events: {
							onhover: {
								enable: true,
								mode: 'repulse'
							},
							onclick: {
								enable: true,
								mode: 'push'
							},
							resize: true
						},
						modes: {
							grab: {
								distance: 400,
								line_linked: {
									opacity: 1
								}
							},
							bubble: {
								distance: 400,
								size: 40,
								duration: 2
							},
							repulse: {
								distance: 200,
								duration: 0.4
							},
							push: {
								particles_nb: 4
							},
							remove: {
								particles_nb: 2
							}
						}
					}
				}}
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					height: '100%',
					left: 0,
					zIndex: 0
				}}
			/>
		</div>
		<div className="Section__content u-wrapper u-ta-c">
			<div className="Project">
				<h2 className="Project__headline">Start building your next</h2>
				<p className="Project__title">
					<ReactRotatingText
						items={[
							' Responsive Web App',
							' Native App',
							' Systems Integration',
							' Custom CMS',
							'... basically, any software...'
						]}
					/>
				</p>
			</div>
		</div>

		<div className="CTA u-wrapper">
			<h3 className="u-title">Customer Connection</h3>
			<p className="CTA__subtitle u-subtitle2">
				Every dot represents a customer and they are all socially connected.
			</p>

			{/* <Button primary="true" onClick={props.onCtaClick}>
				Click to add more
			</Button> */}
		</div>
	</div>
);

export default Hero;
