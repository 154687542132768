import * as React from 'react';

import Footer from '../../components/shared/Footer';
import Header from '../../components/shared/Header';
import Head from '../../components/ui/Head';
import Page from '../../components/ui/Page';
import ContactInfo from './ContactInfo';

import './style.scss';

export interface ContactUsProps {
	pageTitle?: string;
}

class ContactUs extends React.Component {
	constructor(props: ContactUsProps, context?: any) {
		super(props, context);
	}

	public render() {
		return (
			<Page>
				<Head>
					<title>RSS</title>
					<meta name="Description" content="RSS" />
				</Head>

				<Header theme="dark">
					<div className="CTA">
						<h1 className="Title">Contact Us</h1>
						<h2 className="Subtitle">
							Get in touch with us by sending us an email or giving us a call
						</h2>
					</div>
				</Header>

				<ContactInfo />

				<Footer />
			</Page>
		);
	}
}

export default ContactUs;
