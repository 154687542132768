import * as React from 'react';
import { Link } from 'react-scroll';

import Nav from '../../ui/Nav';

import './style.scss';

export interface SiteNavProps {
	isOpen?: boolean;
	theme?: string;
	closeNav?: () => {};
}

const SiteNav = (props: SiteNavProps) => (
	<div
		className={`Site-nav t-${props.theme} ${props.isOpen ? 'is-open' : ''}`}
		onClick={props.closeNav}
	>
		<div className="Site-nav__inner">
			<Nav>
				<Link to="/">
					<span className="Site-nav__item">Home</span>
				</Link>
				{/* <Link to="/services">
					<span className="Site-nav__item">Services</span>
				</Link>

				<Link to="/faq">
					<span className="Site-nav__item">FAQ</span>
				</Link> */}
				<Link activeClass="active" to="AboutUs" spy={true} smooth={true} offset={50} duration={500}>
					<span className="Site-nav__item">About Us</span>
				</Link>
				<Link activeClass="active" to="Services" spy={true} smooth={true} offset={0} duration={500}>
					<span className="Site-nav__item">Services</span>
				</Link>
				<Link activeClass="active" to="Partners" spy={true} smooth={true} offset={0} duration={500}>
					<span className="Site-nav__item">Partners</span>
				</Link>
				<Link
					activeClass="active"
					to="ContactUs"
					spy={true}
					smooth={true}
					offset={50}
					duration={500}
				>
					<span className="Site-nav__item">Contact Us</span>
				</Link>
			</Nav>
		</div>
	</div>
);

export default SiteNav;
