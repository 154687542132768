import * as React from 'react';

import Grid from '../../ui/Grid';
// import Link from '../../ui/RouterLink';

import './style.scss';

const Services = () => (
	<div className="Services__Section u-wrapper">
		<div className="Section__header u-ta-c">
			<h2 className="u-title">Our Services</h2>
			<p className="u-subtitle">
				Customer acquisition and retention is a huge challenge and there is continuous pressure to
				keep up with ever-evolving business needs.
			</p>
		</div>

		<div className="Section__content">
			<Grid wrap={true}>
				<div className="Heading">
					<p className="Heading__item Heading__item--important">
						We assist your business through all these changes and transitions by offering solutions
						that will improve your service offering and client experience which will help you take
						your business to the next level.
					</p>
				</div>
				<div className="Service-list">
					<div className="Service">
						<div className="Service__inner">
							<div className="Service__icon fa fa-connectdevelop" />
							<h4 className="Service__title">Software Development</h4>
							<p>
								We engage in both new and old, adding as well as enhancing existing customer facing
								applications to achieve the desired customer journey.
							</p>
						</div>
					</div>
					<div className="Service">
						<div className="Service__inner">
							<div className="Service__icon fa fa-plug" />
							<h4 className="Service__title">Systems Integration</h4>
							<p>
								Existing systems already have functionality ontop of which customer journeys are
								built. We have the expertise to integrate and enhance these underlying systems.
							</p>
						</div>
					</div>
					<div className="Service">
						<div className="Service__inner">
							<div className="Service__icon fa fa-briefcase" />
							<h4 className="Service__title">Consultancy</h4>
							<p>
								The software Delivery Lifecycle consists of a number of phases through which we can
								offer valuable assistance. We also love to offer a combination of disciplines such
								as Project Analysis and Quality Assurance.
							</p>
						</div>
					</div>
					<div className="Service">
						<div className="Service__inner">
							<div className="Service__icon fa fa-cloud" />
							<h4 className="Service__title">Digital Transformation</h4>
							<p>
								Digital Transformation is an inevitable part of enhancing your Customer Journey. We
								assist businesses by refining processes in order to deliver game changing Customer
								Experiences.
							</p>
						</div>
					</div>
				</div>
			</Grid>
		</div>
	</div>
);

export default Services;
