import * as React from 'react';

import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';

export interface GoogleMapProps {
	google?: any;
}

export class GoogleMap extends React.Component<GoogleMapProps, {}> {
	private mapStyle = {
		width: '100%',
		height: '100%'
	};

	public render() {
		return (
			<Map
				google={this.props.google}
				zoom={15}
				style={this.mapStyle}
				initialCenter={{
					lat: -33.965854,
					lng: 18.840323
				}}
			>
				<Marker
					title={'RSS Head Office'}
					name={'RSS H/O'}
					position={{ lat: -33.965854, lng: 18.840323 }}
				/>
			</Map>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyBDDIm4bXq-EHoScBS38k6s2iLV4ojFGZU'
})(GoogleMap);
