import * as React from 'react';

import './style.scss';

const RWS = () => (
	<div className="Spotlight__Section__RWS">
		<div className="Section__content u-wrapper">
			<div className="Section__header u-ta-c">
				<h2 className="u-title">Real World Solutions</h2>
				<br />

				<br />

				<br />
			</div>
			<div className="RWS" />

			<p className="u-subtitle">
				In our ever changing world where consumers are demanding more from business, access to
				services or one-on-one consulting engagements, we have assisted organisations across various
				industries to meet the demand and excel in service.
			</p>

			<br />
			<p className="u-subtitle">
				In the financial, telecommunication, local and national government sectors we have
				implemented and are maintaining environments that provide the end user with options on how
				they would like to interact with these businesses. With limited time available consumers
				often prefer to start their journey online to save time, the aim should be to let customers
				achive their goal as soon as possible and with as little disruption as possible.
			</p>
			<br />
			<p className="u-subtitle">
				We add value by streamlining processes to make it easy for any client visiting your business
				to instantly establish which service they would like, get information on the time that the
				interaction is likely to take and provide options through the use of notification services.
				Front office operations are improved by providing clients with queue information,
				interchanged with relevant service and marketing related information or entertainment to
				improve the experience.
			</p>
			<br />
			<p className="u-subtitle">
				Information is made available throughout the waiting area both visually and through audio
				which keeps clients informed of their progress through the queues to manage expectations.
				<br />
				<br />
				<b>
					Talk to us about your vision, the unique journey your particular client will take when
					interacting with your business and how we can help you to improve on this.
				</b>
			</p>
			<br />
			<br />
		</div>
	</div>
);

export default RWS;
