import * as React from 'react';

import './style.scss';

const Team = () => (
	<div>
		<br />
		<br />
		<br />
		<br />
		<div className="Section__header u-ta-c">
			<h2 className="team-title">Core team</h2>
		</div>
		<br />
		<br />
		<br />
		<br />
		<div className="staff-desktop">
			<div className="StaffRow">
				<div className="StaffColumn">
					<a target="_blank" href="https://www.linkedin.com/in/righardt-swanepoel-69a00311/">
						<div className="righardt" />
					</a>
					<br />
					<br />

					<p className="StaffText">
						<strong>Righardt Swanepoel</strong>
						<br />
						Managing Director
					</p>
				</div>
				<div className="StaffColumn">
					<a target="_blank" href="https://www.linkedin.com/in/eugene-swanepoel-182a1a14/">
						<div className="eugene" />
					</a>
					<br />
					<br />
					<p className="StaffText">
						<strong>Eugene Swanepoel</strong>
						<br />
						Director
					</p>
				</div>
			</div>
			<div className="StaffRow">
				<div className="StaffColumn">
					<a target="_blank" href="https://www.linkedin.com/in/dina-van-zyl-7bb261121/">
						<div className="dina" />
					</a>
					<br />
					<br />
					<p className="StaffText">
						<strong>Dina van Zyl</strong>
						<br />
						Business Analyst Lead
					</p>
				</div>
				<div className="StaffColumn">
					<a target="_blank" href="https://www.linkedin.com/in/wynand-ernst-70a582a3/">
						<div className="wynand" />
					</a>
					<br />
					<br />
					<p className="StaffText">
						<strong>Wynand Ernst</strong>
						<br />
						Technical Lead
					</p>
				</div>
			</div>
		</div>
		<div className="staff-mobile">
			<a target="_blank" href="https://www.linkedin.com/in/righardt-swanepoel-69a00311/">
				<div className="righardt" />
			</a>
			<br />
			<br />

			<a target="_blank" href="https://www.linkedin.com/in/eugene-swanepoel-182a1a14/">
				<div className="eugene" />
			</a>
			<br />
			<br />
			<a target="_blank" href="https://www.linkedin.com/in/dina-van-zyl-7bb261121/">
				<div className="dina" />
			</a>
			<br />
			<br />
			<a target="_blank" href="https://www.linkedin.com/in/wynand-ernst-70a582a3/">
				<div className="wynand" />
			</a>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
		</div>
	</div>
);

export default Team;
