import * as React from 'react';

// tslint:disable
const image = require('../../../assets/images/png/logo_med.png');
const whatsapp = require('../../../assets/images/png/whatsapp.png');
const call = require('../../../assets/images/png/telephone.png');

export const aboutImage = require('../../../assets/images/png/werare.png');

import './style.scss';

export interface LogoPngProps {
	width?: string;
	height?: string;
}

const LogoPng = (props: LogoPngProps) => <img className="logo" src={image} />;

export const WhatsappLogo = (props: LogoPngProps) => <img className="logo" src={whatsapp} />;
export const CallLogo = (props: LogoPngProps) => <img className="logo" src={call} />;

export default LogoPng;
