import { aboutImage } from 'app/components/ui/LogoPng/LogoPng';

import * as React from 'react';

import './style.scss';

const Spotlight = () => (
	<div className="Spotlight__Section">
		<div className="Section__content u-wrapper">
			<div className="Section__header u-ta-c">
				<h2 className="u-title">About Us</h2>
				<p className="u-subtitle">
					We pride ourselves on the experience we have developing and maintaining world-class
					solutions internationally to help our clients deliver an amazing customer experience.
				</p>
			</div>
			<br />
			<br />

			<div className="Spotlight-list">
				<div className="Spotlight">
					<h4 className="Spotlight__title">Who we are</h4>
					<p>
						The RSS team have been combining their passion for client service and delivery of
						cutting edge solutions since 2017. We believe in working closely alongside your business
						to listen, analyse and deliver best-in-class solutions that make use of a plathora of
						technologies available to enhance the business bottomline.
					</p>
				</div>
				<div className="Spotlight">
					<h4 className="Spotlight__title">What we do</h4>
					<p>
						Our engagement process considers all aspects of delivery, from doing a thorough analysis
						of the environment and business problem to supporting the final solution. Our team
						compliments one another with their skill and extensive knowledge across various
						industries to find the best solution to cater for each unique client requirement.
					</p>
				</div>
				<div className="Spotlight">
					<p>
						<img className="aboutImage" src={aboutImage} />
					</p>
				</div>
			</div>
		</div>
		<br />
		<br />
	</div>
);

export default Spotlight;
