import * as React from 'react';

import CTA from '../../components/shared/CTA';
import Footer from '../../components/shared/Footer';
import Header from '../../components/shared/Header';
import Partners from '../../components/shared/Partners';
import Head from '../../components/ui/Head';
import Page from '../../components/ui/Page';
import Spotlight from './Spotlight';

import './style.scss';

export interface AboutProps {
	pageTitle?: string;
}

class About extends React.Component {
	constructor(props: AboutProps, context?: any) {
		super(props, context);
		this.handleCtaClick = this.handleCtaClick.bind(this);
	}

	public handleCtaClick() {
		window.location.href = 'mailto:info@rss.world';
	}

	public render() {
		return (
			<Page>
				<Head>
					<title>RSS</title>
					<meta name="Description" content="RSS" />
				</Head>

				<Header theme="dark">
					<div className="CTA">
						<h1 className="Title">About Us</h1>
						<h2 className="Subtitle">Discover more about RSS</h2>
					</div>
				</Header>

				<Spotlight />

				<Partners />

				<CTA onCtaClick={this.handleCtaClick} />
				<Footer />
			</Page>
		);
	}
}

export default About;
