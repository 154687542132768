import * as React from 'react';

import './style.scss';

const Partners = () => (
	<div className="Partners__Section">
		<div className="Section__content u-wrapper">
			<div className="Section__header u-ta-c">
				<h2 className="u-title">Our Partners</h2>
			</div>
			<div className="Partners-list">
				<div className="Partners-mobile">
					<a target="_blank" href="https://www.emergequeue.co.za/index.php/clients">
						<div className="Emerge__Logo" />
					</a>
					<a target="_blank" href="http://emergea.mu/about-us/">
						<div className="Emergea__Logo" />
					</a>
					<a target="_blank" href="http://crestech.co.ug/">
						<div className="Crestech__Logo" />
					</a>
					<a target="_blank" href="https://phoenixmanagement.world/">
						<div className="Phoenix__Logo" />
					</a>
					<a target="_blank" href="http://ess-sa.net/our-clients/">
						<div className="ESS__Logo" />
					</a>
					<a target="_blank" href="https://www.dapperstrategies.com/">
						<div className="Dapper__Logo" />
					</a>
				</div>
				<div className="Partners-desktop">
					<div className="PartnerRow">
						<div className="partnerColumn">
							<a target="_blank" href="https://www.emergequeue.co.za/index.php/">
								<div className="Emerge__Logo" />
							</a>
							<p className="partnerText">
								Emerge Queue has been the sole provider of Qmatic queue management and customer
								experience products in South Africa and neighbouring countries since 2009. Through
								cutting-egde technology solutions, we help organisations to improve their customer
								experiences and productivity at every touch point.
							</p>
						</div>
						<div className="partnerColumn">
							<a target="_blank" href="http://emergea.mu/">
								<div className="Emergea__Logo" />
							</a>
							<p className="partnerText">
								Emergea was founded in 2011 and whilst we are based in Black River, Mauritius, we
								operate throughout the African continent. We focus on solution development in the IT
								industry specialising in customer experience(CX) and connectivity solutions for the
								African market.
							</p>
						</div>
						<div className="partnerColumn">
							<a target="_blank" href="http://crestech.co.ug/">
								<div className="Crestech__Logo" />
							</a>
							<p className="partnerText">
								Crestech was founded in 2014 by a team of local and international experts with an
								office in Kampala Uganda, and operates throughout East Africa. We focus on solution
								development in the IT industry specialising in customer experience(CX) and
								connectivity solutions for the African market.
							</p>
						</div>
					</div>
					<br />
					<br />
					<br />
					<div className="PartnerRow">
						<div className="partnerColumn">
							<a target="_blank" href="https://phoenixmanagement.world/">
								<div className="Phoenix__Logo" />
							</a>
							<br />
							<br />
							<p className="partnerText">
								Phoenix brings together the very best expertise when it comes to day-to-day business
								operations and offers key services that allow SME's to focus on the bigger picture
								of growing their businesses.
							</p>
						</div>
						<div className="partnerColumn">
							<a target="_blank" href="http://ess-sa.net/">
								<div className="ESS__Logo" />
							</a>
							<br />
							<br />

							<p className="partnerText">
								Efficient Support Services is a provider if ICT hardware solutions. We Specialise in
								structured cabling services that includes but is not limited to Network
								Installation, Network Cabling, Data and Voice Cabling(Voip), Wireless Solutions,
								CCTV, PABX Systems, Biometrics, POS and Cable Management Services. Efficient Support
								Services was founded in 2010.
							</p>
						</div>
						<div className="partnerColumn">
							<a target="_blank" href="https://www.dapperstrategies.com/">
								<div className="Dapper__Logo" />
							</a>
							<br />
							<br />
							<p className="partnerText">
								We help brands to build and manage their online presence. But we go beyond making
								you look good out there on the web. We create proper, custom-fit digital strategies,
								plans and content that shoot straight for your target audience and objectives.
								There's a reason behind everything we do and we're driven by the results. We'll keep
								on tinkering until it's perfect. Nice and simple, no jargon, no fuss. Just stuff
								that works.
							</p>
						</div>
					</div>
				</div>
			</div>
			<br />

			<br />
		</div>
	</div>
);

export default Partners;
